import React from 'react';
import { 
    List, 
    Datagrid, 
    TextField,
    ReferenceField, 
} from 'react-admin';

import './App.css';

export const TopicList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source='nameUnicode' />
            <TextField source='name' />
        </Datagrid>
    </List>
);