import React from 'react';
import { 
    BooleanInput,
    BooleanField,
    List, 
    Datagrid, 
    TextField, 
    TextInput,
    ReferenceInput, 
    SelectInput,
    ArrayField, 
    SingleFieldList, 
    SimpleForm,
    Create,
    Edit,
    EditButton,
  ShowButton,
  ReferenceField,
  AutocompleteInput
} from 'react-admin';

import './App.css';
import {KhmerTextArea, MyAnswerInput,KhmerTextInput , MyHiddenInput} from './MyCustomInput';
import { BodyField, AnswerField } from './MyCustomField';

// import { makeStyles, Chip } from '@material-ui/core';
// const useQuickFilterStyles = makeStyles(theme => ({
//     chip: {
//         marginBottom: theme.spacing(1),
//     },
// }));
// const QuickFilter = ({ label }) => {
//     const classes = useQuickFilterStyles();
//     return <Chip className={classes.chip} label={label} />;
// };

// const qFilters = [
//     <SearchInput source="q" alwaysOn />,
//     <QuickFilter source="topicId" label="Topic2" defaultValue={2} />,
// ];
const choices = [
    {id:1, name:'1'},
    {id:2, name:'2'},
    {id:3, name:'3'},
    {id:4, name:'4'},
    {id:5, name:'5'},
    {id:6, name:'6'},
    {id:7, name:'7'},
    {id:8, name:'8'},
    {id:9, name:'9'},
 ];
const questionFilters = [
    <KhmerTextInput placeholder="EsVgrk" source="q" label="Search" alwaysOn />,
    <ReferenceInput source="topicId" label="Topic" reference="topics" allowEmpty>
        <SelectInput optionText="nameUnicode" />
    </ReferenceInput>,
    <SelectInput source="difficulty" label="Difficulty" choices={choices} options={{
        color: 'secondary',
    }} />

];

export const QuestionList = (props) => (
    <List filters={questionFilters} {...props}>
        <Datagrid>
            <TextField source="createdAt" />
            <ReferenceField allowEmpty={false} source='topicId' label="Topic"  reference="topics" link={true}>
                <TextField source="nameUnicode" />
            </ReferenceField>
            <TextField source="difficulty" />
            <BodyField source="body" />
            <ArrayField source="answers">
                <SingleFieldList linkType={false}>
                    <AnswerField source="body"/>
                </SingleFieldList>
            </ArrayField>
            <BooleanField source="active" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

export const QuestionEdit = props => (
    <Edit title={<QuestionTitle />} {...props}>
        <SimpleForm>
            {/* <TextInput source="topicId" /> */}
            <ReferenceInput source="topicId" reference="topics">
                <SelectInput optionText="nameUnicode" />
            </ReferenceInput>

            <TextInput source="difficulty" />
            <MyHiddenInput source='correctAnswer' defaultValue={0} />
            <BooleanInput label="Active" source="active" />
            <TextInput source='imageUrl' />
            <KhmerTextArea source='body'/>
            <TextInput multiline source='bodyUnicode'/>
            <MyAnswerInput source='answers' />
        </SimpleForm>
    </Edit>
);

const QuestionTitle = ({ record }) => {
        return <span class='limons1-sm'>{record ? `${record.body}` : ''}</span>;
    };

export const QuestionCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="topicId" reference="topics">
                <SelectInput optionText="nameUnicode" />
            </ReferenceInput>

            <TextInput source="difficulty" required/>
            <MyHiddenInput source='correctAnswer' defaultValue={0} />
            <BooleanInput label="Active" source="active" />
            <TextInput source='imageUrl' />
            <KhmerTextArea source="body" required/>
            <TextInput multiline source='bodyUnicode'/>
            <MyAnswerInput source="body" />
        </SimpleForm>
    </Create>
);