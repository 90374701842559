import { Field } from 'react-final-form';
import './App.css'
import { Labeled} from 'react-admin';

export const KhmerTextArea = () => (
    <Labeled label="Body">
    <span>
        <Field name="body" className='limons1-sm' component="textarea" rows="1" cols="50" type="text" placeholder="bBa©ÚlsMnYr"/>
    </span>
    </Labeled>
);
export const KhmerTextInput = ({ source, placeholder }) => (
    <Field name={source} class='limons1-sm' component="input" type="text" placeholder={placeholder}/>
);

export const MyAnswerInput = () => (    
    <span>
        <Field name='answers.0.id' component="input" type="hidden" defaultValue={0}/>
        <Field name='answers.0.imageUrl' component="input" type="hidden" defaultValue={""}/>
        <Field name='answers.0.body' class='limons1-sm' component="input" type="text" placeholder="bBa©ÚlcemøIy" />
        &nbsp;
        <Field name='answers.1.id' component="input" type="hidden" defaultValue={1}/>
        <Field name='answers.1.imageUrl' component="input" type="hidden" defaultValue={""}/>
        <Field name="answers.1.body" class='limons1-sm' component="input" type="text" placeholder="bBa©ÚlcemøIy" />
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        <Field name='answers.0.bodyUnicode' class='khmer-os-sm' component="input" type="text" placeholder="unicode 1" />
        &nbsp;
        <Field name='answers.1.bodyUnicode' class='khmer-os-sm' component="input" type="text" placeholder="unicode 2" />
        <br/>
        <br/>
        <Field name='answers.2.id' component="input" type="hidden" defaultValue={2}/>
        <Field name='answers.2.imageUrl' component="input" type="hidden" defaultValue={""}/>
        <Field name="answers.2.body" class='limons1-sm' component="input" type="text" placeholder="bBa©ÚlcemøIy" />
        
        &nbsp;
        <Field name='answers.3.id' component="input" type="hidden" defaultValue={3}/>
        <Field name='answers.3.imageUrl' component="input" type="hidden" defaultValue={""}/>
        <Field name="answers.3.body" class='limons1-sm' component="input" type="text" placeholder="bBa©ÚlcemøIy" />

        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        <Field name='answers.2.bodyUnicode' class='khmer-os-sm' component="input" type="text" placeholder="unicode 3" />
        &nbsp;
        <Field name='answers.3.bodyUnicode' class='khmer-os-sm' component="input" type="text" placeholder="unicode 4" />
    </span>
)

export const MyHiddenInput = ({ source, defaultValue }) => (    
    <span>
        <Field name={source} component="input" type="hidden" defaultValue={defaultValue}/>
    </span>
)

