import { ChipField, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    bold: { fontWeight: 'bold', fontFamily: 'Limon S1', fontSize:30 },
    sm: { fontFamily: 'Limon S1', fontSize:30 },
});

export const BodyField = props => {
    const classes = useStyles();
    return <TextField className={classes.bold} {...props} />;
}

export const AnswerField = props => {
    const classes = useStyles();
    return <ChipField className={classes.sm} clickable={false} linkType={false} {...props} />;
}