import React from 'react';
import { 
    BooleanField,
    TextField, 
    ArrayField, 
    SingleFieldList, 
    Show,
  SimpleShowLayout,
  ReferenceField
} from 'react-admin';
import './App.css';
import { BodyField, AnswerField } from './MyCustomField';

export const QuestionShow = props => (
    <Show {...props}>
	<SimpleShowLayout>
        
        <ReferenceField allowEmpty={false} source='topicId' label="Topic"  reference="topics" link={true}>
            <TextField source="nameUnicode" />
        </ReferenceField>

        <TextField source="difficulty" />
        <BooleanField source="active" />
        <TextField source="imageUrl" />
        <BodyField source="body" />
        <ArrayField source="answers">
            <SingleFieldList linkType={false}>
                <AnswerField source="body"/>
            </SingleFieldList>
        </ArrayField>
	</SimpleShowLayout>
    </Show>
);