import * as React from "react";
import {fetchUtils, Admin, Resource } from 'react-admin';
import Dashboard from './Dashboard';
import authProvider from './authProvider';
import Cookies from './helpers/Cookies';


// import simpleRestProvider from 'ra-strapi-rest';
// import dataProvider from './DataProvider';
// const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

import simpleRestProvider from './ra-strapi-rest';
import { QuestionList, QuestionEdit, QuestionCreate } from './questions';
import {QuestionShow} from './questionShow';
import { TopicList } from './TopicList';
import { apiRoot } from "./ApiRoot";
import { ErrorFeedBackCreate, ErrorFeedBackEdit, ErrorFeedBackList } from "./QerrorList";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = Cookies.getCookie('token')
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

const dataProvider = simpleRestProvider(apiRoot, httpClient);

const App = () => (
      // <Admin dashboard={Dashboard} authProvider={authProvider} dataProvider={dataProvider}>
      <Admin dashboard={Dashboard} authProvider={authProvider} dataProvider={dataProvider}>
        {/* <Resource name="posts" list={PostList} edit={PostEdit} create={PostCreate} icon={PostIcon}/> */}
        {/* <Resource name="users" list={UserList} icon={UserIcon} /> */}
        <Resource name="questions" list={QuestionList} edit={QuestionEdit} create={QuestionCreate} show={QuestionShow}/>
        <Resource name="topics" list={TopicList}/>
        <Resource name="qerrorreports" list={ErrorFeedBackList} edit={ErrorFeedBackEdit} create={ErrorFeedBackCreate}/>
      </Admin>
);

export default App;