import React from 'react';
import { 
    List, 
    Datagrid, 
    TextField,
    ReferenceField, 
    SingleFieldList,
    ArrayField,
    Edit,
    EditButton,
    Create,
    BooleanInput,
    SimpleForm,
    TextInput
} from 'react-admin';

import './App.css';
import { AnswerField, BodyField } from './MyCustomField';

const ErrorEditTitle = ({ record }) => {
    return <span class='limons1-sm'>{record ? `${record.id}` : ''}</span>;
};
export const ErrorFeedBackEdit = props => (
    <Edit title={<ErrorEditTitle/>} {...props}>
        <SimpleForm>
            <BooleanInput label="Fixed" source="fixed" />
            <TextInput source="questionId" />
        </SimpleForm>
    </Edit>
);

export const ErrorFeedBackCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <BooleanInput label="Fixed" source="fixed" />
            <TextInput source="questionId" />
        </SimpleForm>
    </Create>
);

export const ErrorFeedBackList = (props) => (
    <List {...props}>
        <Datagrid>
            <ReferenceField allowEmpty={false} source='questionId' label="QuestionId"  reference="questions" link={true}>
                <BodyField source="id" />
            </ReferenceField>
            <ReferenceField allowEmpty={false} source='questionId' label="Body"  reference="questions" link={false}>
                <BodyField source="body" />
            </ReferenceField>
            <ReferenceField allowEmpty={false} source='questionId' label="Answers"  reference="questions" link={false}>
                <ArrayField source="answers">
                    <SingleFieldList linkType={false}>
                        <AnswerField source="body"/>
                    </SingleFieldList>
                </ArrayField>
            </ReferenceField>
            {/* <TextField source='questionId' /> */}
            {/* <TextField source='userId' /> */}
            <TextField source='gameVersion' />
            <TextField source='qversion' />
            <TextField source='spellingError' />
            <TextField source='semanticError' />
            <TextField source='wrongAnswer' />
            <TextField source='feedback' />
            <TextField source='otherError' />
            <TextField source='anonymous' />
            <TextField source='fixed' />
            <EditButton />
        </Datagrid>
    </List>
);